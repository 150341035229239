import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import { mainStore, userToolStore, caixaStore } from '@/utils/store-accessor';
import PeriodSelectorComponent from '@/components/PeriodSelectorComponent.vue';
import _ from 'lodash';
import ImprimirTabelaComponent from '@/components/ImprimirTabelaComponent.vue';
let List = class List extends Vue {
    constructor() {
        super(...arguments);
        this.search = '';
        this.filtroTipoListagem = 1;
        this.tipoOptions = [
            { id: 1, nome: 'Por caixa' },
            { id: 2, nome: 'Por dia' },
        ];
        this.selectPeriodDate = [];
        this.loading = false;
        this.headers = [];
        this.tabelaRef = null;
    }
    get allowView() {
        if (userToolStore.userToolsIndexed.Caixa) {
            return userToolStore.userToolsIndexed.Caixa[0].allow_view;
        }
    }
    get allowCreate() {
        if (userToolStore.userToolsIndexed.Caixa) {
            return userToolStore.userToolsIndexed.Caixa[0].allow_create;
        }
    }
    get allowEdit() {
        if (userToolStore.userToolsIndexed.Caixa) {
            return userToolStore.userToolsIndexed.Caixa[0].allow_edit;
        }
    }
    get allowDelete() {
        if (userToolStore.userToolsIndexed.Caixa) {
            return userToolStore.userToolsIndexed.Caixa[0].allow_delete;
        }
    }
    get caixaItems() {
        return caixaStore.caixas;
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    get totalSaldoFinal() {
        return _.sumBy(this.caixaItems, (item) => {
            // @ts-ignore
            return this.saldoFinal(item);
        });
    }
    saldoFinal(item) {
        return (item.saldo_inicial + item.saldo_material_didatico + item.saldo_curso + item.saldo_outros + item.saldo_saida);
    }
    async buscar() {
        this.loading = true;
        if (this.filtroTipoListagem === 1) {
            this.headers = [
                { text: 'Dia', value: 'data' },
                { text: 'Usuário', value: 'operador' },
                { text: 'Status', value: 'status' },
                { text: 'Total Inicial', value: 'saldo_inicial' },
                { text: 'Total Material didático', value: 'saldo_material_didatico' },
                { text: 'Total Curso', value: 'saldo_curso' },
                { text: 'Total Outros', value: 'saldo_outros' },
                { text: 'Total Saídas', value: 'saldo_saida' },
                { text: 'Total Final sem o valor inicial', value: 'saldo_final' },
                { text: 'Total Final com o valor inicial', value: 'saldo_total' },
                { text: 'Ações', value: 'actions' },
            ];
        }
        else {
            this.headers = [
                { text: 'Dia', value: 'data' },
                { text: 'Total Material didático', value: 'saldo_material_didatico' },
                { text: 'Total Curso', value: 'saldo_curso' },
                { text: 'Total Outros', value: 'saldo_outros' },
                { text: 'Total Final sem o valor inicial', value: 'saldo_final' },
                { text: 'Total Final com o valor inicial', value: 'saldo_total' },
            ];
        }
        await caixaStore.getCaixas({
            data_inicial: this.selectPeriodDate[0],
            data_final: this.selectPeriodDate[1],
            tipo: this.filtroTipoListagem,
        });
        setTimeout(() => {
            this.tabelaRef = this.$refs.tabela;
        }, 2000);
        this.loading = false;
    }
    showDetails(id) {
        this.$router.push({ name: 'main-caixas-edit', params: { id } });
    }
};
List = __decorate([
    Component({
        components: {
            PeriodSelectorComponent,
            ImprimirTabelaComponent,
        },
    })
], List);
export default List;
